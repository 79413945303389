import React from 'react';
import { ComingSoon } from '@/components/empty';
import PrivateProvider from '@/components/providers/PrivateProvider';
import Layout from '@/layouts/LayoutDashboard';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const FullAttributionPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Full Attribution" />
      <PrivateProvider>
        <Layout>
          <ComingSoon />
        </Layout>
      </PrivateProvider>
    </React.Fragment>
  );
};

export default FullAttributionPage;
